<template>
  <div>
    <b-modal ref="dialog" size="lg" @hidden="cameraModalClose" hide-footer title="作成">
      <b-row>
        <b-col>
          <b-form-group label="カメラキー" label-for="">
            <b-form-input type="text" v-model="camera.key" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="名前">
            <b-form-input type="text" v-model="camera.name"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            class="fa fa-dot-circle-o mx-1"
            v-on:click="createcamera()"
          >作成</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { CREATE_CAMERA } from "../../components/camera-mutation";

export default {
  components: {},
  data() {
    return {
      camera: {
        key: null,
        name: null,
      },
    };
  },
  props: {},
  watch: {},
  computed: {},
  methods: {
    init: function() {},
    cameraModalClose: function() {
      this.camera = {
        key: null,
        name: null,
      };
    },
    // --------------------------------
    // modalを開く
    // --------------------------------
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    hideDialog: function() {
      this.$refs["dialog"].hide();
    },
    createcamera: function() {
      this.$apollo
        .mutate({
          mutation: CREATE_CAMERA,
          variables: {
            input: this.camera
          }
        })
        .then(data => {
          this.$emit("refetch");
          this.$refs["dialog"].hide();
        })
        .catch(error => {
          console.error(error);
          alert("登録エラー");
        });
    }
  }
};
</script>
