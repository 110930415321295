import gql from "graphql-tag";

export const CREATE_CAMERA = gql`
  mutation createCamera($input:CameraInput!){
    createCamera(input:$input){
      camera{
        key
        name
      }
    }
  }
`;


export const UPDATE_CAMERA = gql`
  mutation updateCamera($id:ID!,$input:CameraInput!){
    updateCamera(id:$id,input:$input){
      camera{
        id
        key
        name
      }
    }
  }
`;


export const DELETE_CAMERA = gql`
  mutation deleteCamera($id:ID){
    deleteCamera(id:$id){
      camera{
        id
        key
        name
      }
    }
  }
`;
