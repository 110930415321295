<template>
  <div>
    <b-modal ref="dialog" size="lg" @hidden="closeCameraModal" hide-footer title="カメラ情報の編集">
      <b-row>
        <b-col>
          <b-form-group label="カメラキー" label-for="input-camera-key">
            <b-form-input type="text" v-model="camera.key"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="名前" label-for="input-camera-name">
            <b-form-input type="text" v-model="camera.name"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            class="fa fa-dot-circle-o mx-1"
            v-on:click="updateCamera()"
          >更新</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { UPDATE_CAMERA } from "../../components/camera-mutation";

export default {
  components: {},
  data() {
    return {
      camera: {
        key: null,
        name: null,
      },
    };
  },
  props: {
    propCamera: {
      type: Object,
      require: false,
      default: () => ({})
    }
  },
  watch: {
    propCamera: function() {
      this.init();
    }
  },
  computed: {},
  methods: {
    init: function() {
      console.log(this.propCamera);
      this.camera = {
        key: this.propCamera.key,
        name: this.propCamera.name,
      };
    },
    closeCameraModal: function() {
      this.init();
    },
    // --------------------------------
    // modalを開く
    // --------------------------------
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    hideDialog: function() {
      this.$refs["dialog"].hide();
    },
    updateCamera: function() {
      this.$apollo
        .mutate({
          mutation: UPDATE_CAMERA,
          variables: {
            id: this.propCamera.id,
            input: this.camera
          }
        })
        .then(data => {
          this.$emit("refetch");
          this.$refs["dialog"].hide();
        })
        .catch(error => {
          console.error(error);
          alert("登録エラー");
        });
    }
  }
};
</script>
