import gql from "graphql-tag";

export const CAMERA_QUERY = gql`
  query {
    cameras {
      edges{
        node{
          id
          key
          name
        }
      }
    }
  }
`;
