<template>
  <div>
    <camera-create-modal ref="cameraCreateModal" @refetch="cameraRefetch" />
    <camera-update-modal ref="cameraUpdateModal" :propCamera="camera" @refetch="cameraRefetch" />
    <camera-delete-modal ref="cameraDeleteModal" :cameraId="cameraId" @refetch="cameraRefetch" />
    <div v-if="$apollo.loading">Loading...</div>
    <div v-else>
      <b-table responsive :fields="list" :items="this.cameras.edges">
        <template v-slot:cell(key)="data">
          <td>{{data.item.node.key}}</td>
        </template>
        <template v-slot:cell(name)="data">
          <td>{{data.item.node.name}}</td>
        </template>
        <template v-slot:cell(update)="data">
          <b-button
            type="submit"
            size="sm"
            variant="primary"
            class="icon-pencil"
            v-on:click="showUpdateDialog(data.item.node)"
          ></b-button>
        </template>
        <template v-slot:cell(delete)="data">
          <b-button
            type="submit"
            size="sm"
            variant="danger"
            class="icon-trash"
            v-on:click="showDeleteDialog(data.item.node.id)"
          ></b-button>
        </template>
      </b-table>
      <b-form-group>
        <b-button
          type="submit"
          size="sm"
          class="icon-plus"
          variant="outline-primary"
          v-on:click="showCreateDialog()"
        >新規作成</b-button>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import CameraCreateModal from "./CameraCreateModal";
import CameraUpdateModal from "./CameraUpdateModal";
import CameraDeleteModal from "./CameraDeleteModal";
import { CAMERA_QUERY } from "../../components/camera-query";
import { nullCheckConvert } from "../../components/null-check";

// const DEFAULT_FARMER_ID = "RmFybWVyTm9kZTox";
export default {
  components: {
    CameraCreateModal,
    CameraUpdateModal,
    CameraDeleteModal
  },
  data() {
    return {
      list: [
        { key: "key", label: "カメラキー" },
        { key: "name", label: "名前" },
        { key: "update", label: "編集" },
        { key: "delete", label: "削除" }
      ],
      camera: null,
      cameras: [],
      cameraId: null
    };
  },
  apollo: {
    cameras: {
      query: CAMERA_QUERY,
      variables: {
        // farmerId: DEFAULT_FARMER_ID
      },
      result({ data, loading }) {},
      error(error) {
        console.error("camera", error);
      }
    }
  },
  methods: {
    // --------------------------------
    // modalを表示
    // --------------------------------
    showCreateDialog: function() {
      this.$refs.cameraCreateModal.showDialog();
    },
    // --------------------------------
    // modalを表示
    // --------------------------------
    showUpdateDialog: function(camera) {
      this.camera = camera;
      this.$refs.cameraUpdateModal.showDialog();
    },
    // --------------------------------
    // modalを表示
    // --------------------------------
    showDeleteDialog: function(id) {
      this.cameraId = id;
      this.$refs.cameraDeleteModal.showDialog();
    },
    // --------------------------------
    // 畑情報の再取得
    // --------------------------------
    cameraRefetch: function() {
      this.$apollo.queries.cameras.refetch();
    }
  }
};
</script>
